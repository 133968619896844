<template>
  <div>
    <h2>Pagina di Import Condominio</h2>

    <h3>Dati di Sistema</h3>
    <el-row :gutter="15" :key="updatePickers">
      <el-col :span="12">
        <h4>Cliente Sunny</h4>
        <el-select-v2
            v-model="selectedOwner"
            filterable
            :options="admins"
            placeholder="Seleziona"
            style="width: 100%;"
        />
      </el-col>
      <el-col :span="12" class="nowrap">
        <h4 class="ellipsis">Amministratore Condominiale</h4>
        <el-select-v2
            v-model="selectedCondoAdmin"
            filterable
            :options="admins"
            placeholder="Seleziona"
            style="width: 100%;"
        />
      </el-col>
    </el-row>
    <el-row :gutter="15">
      <el-col :span="12">
        <h4>Gestore Calore/Acqua</h4>
        <el-select-v2
            v-model="selectedProvider"
            filterable
            :options="admins"
            placeholder="Seleziona"
            style="width: 100%;"
        />
      </el-col>
      <el-col :span="12">
        <h4>Ditta Assistenza Tecnica</h4>
        <el-select-v2
            v-model="selectedManteiner"
            filterable
            :options="admins"
            placeholder="Seleziona"
            style="width: 100%;"
        />
      </el-col>
    </el-row>
    <el-row :gutter="15">
      <el-col :span="12">
        <h4>Logo da visualizzare</h4>
        <el-select v-model="selectedImg"  style="width: 100%;">
          <el-option
              v-for="(label, key) in optionsSelectImg"
              :key="key"
              :label="label"
              :value="key"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>

    <h3>Dati Anagrafici</h3>
    <el-row>
      <el-col :span="18">
        <h4>Denominazione Condominio</h4>
        <el-input v-model="notes" placeholder="Inserisci la denominazione del condominio"/>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>Indirizzo</h4>
        <form-address :fields="addressFields" @update="updateAddress"></form-address>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="18">
        <h4>Codice Fiscale</h4>
        <el-input v-model="fiscalCode" placeholder="Inserisci il codice fiscale del condominio"/>
      </el-col>
    </el-row>

    <h3>Dati Gestionali</h3>
    <el-row>
      <el-col :span="12">
        <h4>Data Inizio Stagione</h4>
        <p></p>
        <el-row :gutter="15">
          <el-col :span="8">
            <span>Seleziona giorno</span>
            <el-select v-model="dateDay" placeholder="Seleziona giorno">
              <el-option
                  v-for="day in 31"
                  :label="day"
                  :key="day"
                  :value="day"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            <span>Seleziona mese</span>
            <el-select v-model="dateMonth" placeholder="Seleziona mese">
              <el-option
                  v-for="month in monthList"
                  :label="month"
                  :key="month"
                  :value="month"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="12">
        <h4>Data Fine Stagione</h4>
        <p></p>
        <el-row :gutter="15">
          <el-col :span="8">
            <span>Seleziona giorno</span>
            <el-select v-model="endDateDay" placeholder="Seleziona giorno">
              <el-option
                  v-for="day in 31"
                  :label="day"
                  :key="day"
                  :value="day"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            <span>Seleziona mese</span>
            <el-select v-model="endDateMonth" placeholder="Seleziona mese">
              <el-option
                  v-for="month in monthList"
                  :label="month"
                  :key="month"
                  :value="month"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>File CSV</h4>
        <el-upload :key="uploadRefresh"
            style="margin-bottom: 15px"
            drag
            action=""
            :on-change="handleChange"
            :on-remove="handleRemove"
            :auto-upload="false"
            accept=".csv"
            ref="upload">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">Trascina il file qui o <em>clicca per importare</em></div>
          <template #tip>
            <div class="el-upload__tip">
              file csv contenente i dati in modo corretto
            </div>
          </template>
        </el-upload>
        <el-button v-if="file!==null"
                   type="primary"
                   @click="openPreviewDialog">
          {{$t("buttons.continue")}}
          <span class="el-icon-arrow-right"></span>
        </el-button>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <table v-if="newUsers.length > 0">
          <tr>
            <th>Nominativo</th>
            <th>Username (Matricola)</th>
            <th>Password predefinita</th>
          </tr>
          <tr v-for="usr in newUsers" :key="usr.id">
            <td>{{ usr.name }} {{ usr.surname }}</td>
            <td>{{ usr.email }}</td>
            <td>{{ usr.userCode }}</td>
          </tr>
        </table>
      </el-col>
    </el-row>

    <csv-preview-dialog @submit="submit" ref="csvPreviewDialog" v-bind:fileList="[file]"></csv-preview-dialog>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import UserMixin from "../../mixins/UserMixin";
import AddressMixin from "../../mixins/AddressMixin";
import FormAddress from "../../components/FormAddress";
import {getAddressFields} from "../../static/formFields";
import FileMixin from "../../mixins/FileMixin";
import DateMixin from "../../mixins/DateMixin";
import CsvPreviewDialog from "../../components/CsvPreviewDialog";
import globalProperties from "../../main";

export default {
  name: "CondominiumImport",
  components: {FormAddress, CsvPreviewDialog},
  mixins: [UserMixin, AddressMixin, FileMixin, DateMixin],
  data() {
    return {
      updatePickers: 0,
      admins: [],
      selectedCondoAdmin: null,
      selectedOwner: null,
      selectedProvider: null,
      selectedManteiner: null,
      selectedImg: "default",
      addressFields: null,
      notes: "",
      file: null,
      addressValues: {},
      newUsers: [],
      optionsSelectImg: {
        "default": "Default",
        "owner": "Cliente Sunny",
        "condominiumAdministrator": "Amministratore Condominiale",
        "supplier": "Gestore Calore/Acqua",
        "serviceTechnician": "Ditta Assistenza Tecnica"
      },
      dateDay: 1,
      dateMonth: "Set",
      endDateDay: 30,
      endDateMonth: "Giu",
      fiscalCode: "",
      uploadRefresh: false
    }
  },
  computed: {
    ...mapGetters({
      adminList: "condominiumAdministratorList",
      currentMeter: "currentMeter"
    })
  },
  methods: {
    ...mapActions({
      fetchAdmins: "fetchAllCondominiumAdministrators",
      createCondominium: "createCondominium",
      registerUser: "registerUser",
      createApartment: "createApartment",
      createMeter: "createMeter",
      startForceLoading: "startForceLoading",
      stopForceLoading: "stopForceLoading",
      fetchMeterBySerial: "fetchMeterBySerial"
    }),
    updateAddress(field, value) {
      this.addressValues[field] = value;
    },
    handleChange(file, fileList) {
      if (file.raw.type!=="application/vnd.ms-excel"){
        fileList.pop();
        this.$message.error('File di formato non supportato');
      } else {
        fileList.pop();
        fileList[0]=file;
        this.file = file;
      }
    },
    handleRemove(file, fileList) {
      this.file = null
      file = null;
      fileList.pop();
    },
    async submit() {
      this.startForceLoading();

      //lettura da file
      const text = await this.readTextFile(this.file.raw);
      const dataArray = await this.CSVToObj(text, ";");

      //controllo duplicati matricole dei contatori
      let duplicates = [];
      for (const row of dataArray) {
        await this.fetchMeterBySerial(row.serialNumber);
        // console.log(this.currentMeter)
        if (this.currentMeter){
          duplicates.push(this.currentMeter);
        }
      }
      if (duplicates.length>0){
        // this.$refs.upload.clearFiles();//non funziona, trovare soluzione alternativa
        this.refreshUpload();
        this.file = null;
        globalProperties.$message({
          message: "Impossibile proseguire con il caricamento del condominio, sono presenti dei duplicati tra le matricole dei contatori",
          type: "error",
          showClose: true,
          duration: 0
        });
      } else {

        //registrazione campi
        const condominiumId = await this.makeCondominium();
        let apartmentNumber = -1;
        let apartment;
        for (const row of dataArray) {
          if (row.numberA !== apartmentNumber) {
            const user = await this.makeUser(row);
            this.newUsers.push(user);
            apartment = await this.makeApartment(row, condominiumId, user);
            apartmentNumber = row.numberA;
          }
          await this.makeMeter(row, apartment);
        }
        this.$refs.upload.clearFiles();
        this.file = null;
        this.$refs.upload.clearFiles();
        this.$message.success('Dati caricati correttamente');
      }
      this.stopForceLoading();

    },
    async makeMeter(row, apartment) {
      const meter = {
        serialNumber: row.serialNumber,
        apartment: apartment.id,
        brand: row.brand,
        meterType: row.meterType,
        description: row.descriptionC,
        kMeter: row.kMeter
      }
      return this.createMeter(meter);
    },
    async makeApartment(row, condominium, user) {
      const apt = {
        condominium,
        number: row.numberA,
        floor: row.floor,
        user: user.id,
        description: row.descriptionA,
        kApartment: row.kApartment
      }
      return this.createApartment(apt);
    },
    async makeUser(row) {
      const usr = {
        name: row.name,
        surname: row.surname,
        notes: row.notes,
        email: row.serialNumber,
        address: {
          street: this.addressValues.street,
          number: this.addressValues.number,
          city: this.addressValues.city,
          state: this.addressValues.state,
          country: this.addressValues.country,
          zip: this.addressValues.zip,
        }
      }
      return this.registerUser(usr);
    },
    async makeCondominium() {
      const condominium = {
        owner: this.selectedOwner,
        condominiumAdministrator: this.selectedCondoAdmin,
        supplier: this.selectedProvider,
        serviceTechnician: this.selectedManteiner,
        description: this.notes,
        address: {
          street: this.addressValues.street,
          number: this.addressValues.number,
          city: this.addressValues.city,
          state: this.addressValues.state,
          country: this.addressValues.country,
          zip: this.addressValues.zip,
        },
        imgSource: this.selectedImg,
        readingsStartDate: this.stringifySeasonDate(this.dateMonth, this.dateDay),
        readingsEndDate: this.stringifySeasonDate(this.endDateMonth, this.endDateDay),
        fiscalCode: this.fiscalCode
      };
      const newCondo = await this.createCondominium(condominium);
      return newCondo.id;
    },
    async openPreviewDialog() {
      await this.$refs.csvPreviewDialog.show();
    },
    getOwnerName(id) {
      //return list.findIndex(id);
      if (this.admins.length > 0) {
        return this.admins.find((el) => el.value === id).label;
      }
    },
    refreshUpload(){
      this.uploadRefresh = !this.uploadRefresh;
    }
  },
  async beforeMount() {
    this.addressFields = getAddressFields()[0].fields;
    await this.fetchAdmins();
    this.admins = this.adminList.map(el => ({
      value: el.id,
      label: this.getFullName(el)
    }))
    this.admins.sort((a, b) => {
      if (a.label.toUpperCase() > b.label.toUpperCase()) {
        return 1;
      } else {
        return -1;
      }
    })
  },

}
</script>

<style scoped>

h3{
  margin-bottom: 0;
}



</style>
